import logo from "../images/TPH-WEDNESDAY-SCHOOL-BLACK.jpeg";
const SubmissionSuc = (props) => {
    return (
        <div id="thank_container"> 
        
            <div style={{marginTop: '4%'}}>
             <h1 style={{textAlign: 'center'}}>Thank you!</h1>
             <h1 style={{textAlign: 'center'}}>You have succesfully submitted your files!</h1>
             </div>
             <button id="lg_but" style={{marginTop: '5%', minHeight: '40px', padding: '10px'}} onClick={props.handleMoreContent}>Upload more content</button>
             <button id="lg_but" style={{marginTop: '2%', minHeight: '40px'}} onClick={props.handleLogout}>Logout</button>
             <img
            src={logo}
            id="thank_logo"
            
          />
    
     
        </div>
    )

};



export default SubmissionSuc;