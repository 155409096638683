import "../App.css";
import React, { useState } from "react";
import logo from "../images/TPH-WEDNESDAY-SCHOOL-BLACK.jpeg";
import tphLogo from "../images/TPH_BLACK-01[17].png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = (props) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');




  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  };
  const handleLogin = () => {
    axios.post('https://bvtv.tdjpartners.com/login',
    {args: {
      process: "login",
      jwt: process.env.REACT_APP_SECRET_JWT,
      type: ""
}},

// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IndzYWRtaW4iLCJwYXNzd29yZCI6InNjaG9vbDM2MCEifQ.cuRlhXmVuLreWrOgysQw-dWZ2XGTcqmezCMvtS_-f7Y
     {
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer AltjatLpN1SiOpbjdAEHUupCVCrno4bu4WS5JKGdyHJKkj6hIlJNOA=='
      }
    })
    .then((res) => {
      if(email == 'wsadmin' && password == 'school360!') {
        navigate("/content-download", { state: 1 });
      } else {
        setError('Invalid username or password')
      }
    })
    .catch((err) => {
      console.log(err)
    })
  };


    return (
        <div id="form_Container">
        <div id="login_title">
          <h2>LOGIN</h2>
        </div>
        <div>
          <label>Username:</label>
          <input type={"email"} onChange={handleEmailChange} name='email' value={email} placeholder="Enter your username"></input>
        </div>
        <div>
          <label>Password:</label>
          <input type={"password"} onChange={handlePasswordChange} name='password' value={password} placeholder="Enter your password"></input>
        </div>
        <div id="login_button">
          <button id="lg_but" onClick={handleLogin}>LOGIN</button>
        </div>
        <div><h3 style={{color: 'red'}}>{error}</h3></div>
        <div id="logo_container">
          <img
            id="tph_logo"
            src={tphLogo}
          
          />
          <img
            id="w_logo"
            src={logo}
            
          />
         
        </div>
      </div>
    )

};


export default Login;
