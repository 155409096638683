import React, { useEffect, useState } from "react";
import "./App.css";
import Login from "./components/login";
import { useNavigate } from "react-router-dom";




function App() {
  const [room, setRoom] = useState();


  const navigate = useNavigate();

  const handleRegistrationButton = () => {
    navigate("/registration");
  };

  const handleLogin = () => {
    navigate("/content-download");
  };



  return (

    <div id="container_wrapper">
      <h1 id="login_header">WEDNESDAY SCHOOL CONTENT</h1>
   
      <Login />
   
      
    </div>
  );
}

export default App;
