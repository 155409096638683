import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavigationBar from "../components/navigation";
import fileDownload from "js-file-download";

const Home = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [change, setChange] = useState(false);
  const [room, setRoom] = useState(1);
  const [className, setClassName] = useState("");

  useEffect(() => {
    if (room == 1) {
      setClassName("Business & Entrepreneurship");
    } else if (room == 2) {
       setClassName("Relationship Management");
    } else if (room == 3) {
     setClassName("Spiritual Development");
    } else if (room == 4) {
      setClassName("Marriage Maximization");
     } else if (room == 5) {
      setClassName("Finances & Wealth");
     }
    axios
      .get(`https://payment.tdjakes.org/ws/listfiles/${room}`)
      .then((res) => {
        setDocuments([res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [room, change]);

  const handleRoom = (e) => {

    setRoom(e.target.value);
  };

  const handleUpload = () => {
    navigate("/content-download", { state: room });
  };

  const handleDelete = (title) => {
    axios
      .post(`https://payment.tdjakes.org/ws/delete/${room}/?title=${title}`)
      .then((res) => {
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDownload = (filename) => {
    axios
      .get(
        `https://payment.tdjakes.org/ws/download/${room}/?title=${encodeURIComponent(filename)}`,
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        fileDownload(res.data, filename);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <NavigationBar room={room} />

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "90px" }}
      >
        <div
        className="review_item_container"
          style={{
            backgroundColor: "white",
            maxHeight: "600px",
            width: "70%",
            overflow: "hidden",
            overflowY: "scroll",
            borderColor: "black",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "20px",
            boxShadow: "1px 2px 9px #696969",
          }}
        >
          <div
            id="review_selection"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label style={{ marginRight: "15px", marginLeft: "15px" }}>
              Select Class:{" "}
            </label>
            <select
              id="room"
              defaultValue={"1"}
              onChange={(e) => handleRoom(e)}
            >
              <option value="1">Business & Entrepreneurship </option>
              <option value="2">Relationship Management</option>
              <option value="3">Spiritual Development</option>
              <option value="4">Marriage Maximization</option>
              <option value="5">Finances & Wealth</option>
            </select>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>Content</h2>
            {documents[0]?.length == 0 ? (
              <h3>No Content Available</h3>
            ) : (
              documents[0]?.map((item, index) => {
                return (
                  <div
                    key={index}
                    id="item_list"
                    style={{
                      display: "flex",
                      width: "95%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderColor: "black",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      margin: "5px",
                      marginBottom: "20px",
                      padding: "5px",
                      borderRadius: "10px",
                      backgroundColor: "rgba(211, 211, 211, 0.4)",
                    }}
                  >
                    <h3>{item.filename}</h3>
                    <div>
                      <button
                        onClick={() => handleDownload(item.filepath)}
                        style={{
                          cursor: "pointer",
                          marginRight: "10px",
                          backgroundColor: "transparent",
                          borderWidth: "1px",
                          borderRadius: "7px",
                          padding: "5px",
                        }}
                      >
                        Download
                      </button>
                      <button
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#E33437",
                          color: "white",
                          border: "none",
                          marginRight: "20px",
                          padding: "5px",
                          borderRadius: "7px",
                        }}
                        onClick={() => handleDelete(item.filepath)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })
            )}

            <button
              onClick={handleUpload}
              id="upload_but"
              style={{
                backgroundColor: "#5897EE",
                color: "white",
                marginBottom: "10px",
                marginRight: "auto",
                marginLeft: "auto",
                padding: "10px",
                borderRadius: "10px",
                border: "none",
                fontSize: 16,
              }}
            >
              Upload content to class {className}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
