import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiUpload2Fill } from "react-icons/ri";
import SubmissionSuc from "./SubmissionSuc";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationBar from "./navigation";
import axios from 'axios';

const ContentDownload = () => {
  const navigate = useNavigate();
  const { state } = useLocation();


  const [source, setSource] = useState([]);
  // const [state, setState] = useState(false);
  const [submittedFiles, setSumbittedFiles] = useState(false);
  const [room, setRoom ] = useState(state.state || state);
  const [className, setClassName] = useState("");


  const handleFileChange = (event) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    // console.log(chosenFiles);
    setSource([chosenFiles]);
  };

  const SubmitFiles = async () => {

    const formData = new FormData()
    source[0]?.map((item) => {
      formData.append('file', item)
    }) 



    axios.post(`https://payment.tdjakes.org/ws/fileupload/${room}`, formData, {
      'headers': {
        'Authorization': `Bearer ${process.env.REACT_APP_BEARER}`,
        // 'Cookie': 'PHPSESSID=03qrolr375f0qdd7kc2a6qkgb0'
      }
    })
    .then((res) => {
      setSumbittedFiles(true);
    })
    .catch((err) => {
      console.log(err)
    })
    
  };

  const handleTryAgain = () => {
    setSource([]);
  };

  const handleMoreContent = () => {
    setSumbittedFiles(false);
    setSource([]);
  };

  const handleLogout = () => {
    navigate("/");
    setSumbittedFiles(false);
    setSource([]);
  };
  const removeFile = (index) => {
    if ( index === 0 ) {
      setSource([])
    } else {
      setSource([source[0].filter((o, i) => index !== i)]);
    }    
  };
  const handleRoom = (e) => {
    setRoom(e.target.value);
};



useEffect(() => {
  if (room == 1) {
    setClassName("Business & Entrepreneurship");
  } else if (room == 2) {
    setClassName("Relationship Management");
  } else if (room == 3) {
   setClassName("Spiritual Development");
  } else if (room == 4) {
    setClassName("Marriage Maximization");
   } else if (room == 5) {
    setClassName("Finances & Wealth");
   }
},[room])

  return (
    <div
    >
      <NavigationBar />
    <div
     style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}>
      {!submittedFiles ? (
        <div
          id="upload_box"
          style={{marginTop: '40px'}}
        
        >
          <div className="header-container"
            style={{
              borderBottomColor: "black",
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
              width: "100%",
              textAlign: "center",
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <h2 style={{marginLeft: '20px'}}>Upload Content to Class -  {className}</h2>
            <div id="upload_selection" style={{display: 'flex', marginRight: '20px'}}>
            <label style={{marginRight: '10px'}}>Select Class: </label>
          <select id='room' defaultValue={room} onChange={(e) => handleRoom(e)}>
          <option value="1">Business & Entrepreneurship</option>
          <option value="2">Relationship Management</option>
          <option value="3">Spiritual Development</option>
          <option value="4">Marriage Maximization</option>
          <option value="5">Finances & Wealth</option>
          </select>
            </div>
          </div>
          {!source.length ? (
            <>
              {" "}
              <div 
                id="upload_container"
              >
                <RiUpload2Fill
                  id="upload_logo"
                  
                  color={"rgba(0, 0, 0, .8)"}
                />
              </div>
              <div 
                id="input_container"
              >
                <div id="upload_text">
                  <p style={{}}>Upload Files</p>
                  <p>or drag and drop files</p>
                </div>
                <input
                  id="choose_file"
                  name="uploadfile"
                  className="files_input"
                  type="file"
                  title=" "
                  multiple
                  onChange={handleFileChange}
                  accept=".mov,.mp4, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg, application/pdf, application/vnd.ms-powerpoint, .ppt, .pptx"
                />

                <div
                  style={{
                    fontSize: "1.5rem",
                    marginTop: "1%",
                    marginBottom: "1%",
                  }}
                >
                </div>
              </div>
            </>
          ) : (
            <>
              <div style={{ width: "90%" }}>
                {source[0]?.map((item, index) => {
                  return (
                     <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <h5
                        onClick={() => removeFile(index)}
                        style={{ marginRight: "5%", cursor: "pointer", }}
                      >
                        X
                      </h5>
                      <h5 id="file_name" style={{ fontSize: 18, wordWrap: 'break-word', width: '90%'}}>{`${item.name}`}</h5>
                    </div> 
                   
                  );
                })}
              </div>
              <button id="submit_but" onClick={SubmitFiles}>
                Submit Files
              </button>{" "}
              <button
                id="try_but"
                style={{ marginTop: "2%" }}
                onClick={handleTryAgain}
              >
                Try Again
              </button>
            </>
          )}
        </div>
      ) : (
        <SubmissionSuc
          handleMoreContent={handleMoreContent}
          handleLogout={handleLogout}
        />
      )}
      </div>
    </div>
  );
};

export default ContentDownload;
