import React from 'react';
import { Link } from 'react-router-dom'
import logo from "../images/white.png";
import "../App.css";


const NavigationBar = (props) => {
    return (
        <div className='nav_container' style={{backgroundColor: 'rgba(0, 0, 0, .4)', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div >
                <img className='logo_item'
            style={{ width: '100px', marginLeft: '50px'}
            }
            src={logo}
            
          />
            </div>
            <div className='link_container' style={{display: 'flex', justifyContent: 'space-evenly', width: '40%'}}>
            <Link to="/content-download" id='link' state={{ state: props.room}}>Upload</Link>
            <Link to='/home' className='link'>Review Content</Link>
            <Link to='/' id='link'>Logout</Link>
            </div>
        </div>
    )
};

export default NavigationBar;